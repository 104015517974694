<script lang="ts" setup>
import { Status, StatusType } from '@injectivelabs/utils'
import { ROUTES } from '@/app/utils/constants'
import { BusEvents, Modal } from '@/types'

const route = useRoute()
const appStore = useAppStore()
const spotStore = useSpotStore()
const tokenStore = useTokenStore()
const modalStore = useModalStore()
const walletStore = useWalletStore()
const accountStore = useAccountStore()
const exchangeStore = useExchangeStore()
const derivativeStore = useDerivativeStore()
const { $onError } = useNuxtApp()

const status = reactive(new Status(StatusType.Loading))
const isOpenSidebar = ref(false)

const container = computed(() => document.getElementById('pro'))

type Theme = 'light' | 'dark'
const LOCAL_STORAGE_THEME_KEY = 'theme'
const darkMode = useState('theme', () => false)
const setTheme = (newTheme: Theme) => {
  localStorage.setItem(LOCAL_STORAGE_THEME_KEY, newTheme)
  darkMode.value = newTheme === 'dark'
}


const showFooter = computed(() =>
  ROUTES.footerEnabledRoutes.includes(route.name as string)
)

onMounted(() => {
  Promise.all([walletStore.init(), tokenStore.fetchSupplyTokenMeta()])
    .catch($onError)
    .finally(() => {
      status.setIdle()
    })

  // Actions that should't block the app from loading
  Promise.all([
    appStore.init(),
    spotStore.init(),
    derivativeStore.init(),
    exchangeStore.initFeeDiscounts(),
    tokenStore.fetchSupplyTokenMeta()
  ])

  openDevModeModal()
  useEventBus<string>(BusEvents.NavLinkClicked).on(onCloseSideBar)

  const isDarkModePreferred = window.matchMedia('(prefers-color-scheme: dark)').matches
  const themeFromLocalStorage = localStorage.getItem(LOCAL_STORAGE_THEME_KEY) as Theme
  if (themeFromLocalStorage) {
    setTheme(themeFromLocalStorage)
  } else {
    setTheme('dark')
  }
})

watch(darkMode, (selected) => {
  setTheme(selected ? 'dark' : 'light')
})

onWalletConnected(() => {
  Promise.all([accountStore.fetchAccountPortfolio()]).catch($onError)
})

function onOpenSideBar() {
  isOpenSidebar.value = true

  container.value?.classList.add('overflow-y-hidden')
}

function onCloseSideBar() {
  if (isOpenSidebar.value) {
    isOpenSidebar.value = false

    container.value?.classList.remove('overflow-y-hidden')
  }
}

function toggle() {
  darkMode.value = !darkMode.value
}

function openDevModeModal() {
  const devModeExistsInQuery =
    route.query.devMode && route.query.devMode === 'true'

  if (devModeExistsInQuery && !walletStore.isUserWalletConnected) {
    modalStore.openModal({ type: Modal.DevMode })
  }
}
</script>

<template>
  <div
    id="pro"
    class="w-full h-full min-h-screen relative"
    :class="[{ 'dark': darkMode }, { 'light': !darkMode }]"
  >
    <transition name="page" appear>
      <AppHocLoading :status="status">
        <div>
          <LayoutSidebarMobile
            v-bind="{
              isOpenSidebar
            }"
            @sidebar:closed="onCloseSideBar"
          />
          <client-only>
            <div class="relative">
              <LayoutTopbar
                :is-sidebar-open="isOpenSidebar"
                @sidebar:opened="isOpenSidebar = true"
                @sidebar:closed="onCloseSideBar"
                @toggle="toggle"
              >
              </LayoutTopbar>
              <main
                class="w-full h-full min-h-screen-excluding-header flex flex-col"
                :class="{ 'pt-12': isOpenSidebar}"
              >
                <div
                class="relative h-full-flex"
                >
                  <NuxtPage />
                </div>
                <LayoutFooter v-if="showFooter" />
              </main>

              <ModalsInsufficientInjForGas />
              <ModalsNinjaPassWinner />
              <!-- hide survey for now but can be resurrected and modified for future surveys -->
              <!-- <ModalsUserFeedback /> -->
              <ModalsDevMode />
              <AppConfetti />
              <div id="modals" />
            </div>
          </client-only>
        </div>
      </AppHocLoading>
    </transition>
    <BaseNotifications
      class="z-[1110] fixed inset-0 flex flex-col gap-2 justify-end items-end p-6 pointer-events-none"
    >
      <template #notification="{ notification }">
        <BaseNotification
          :notification="notification"
          class="pointer-events-auto primary-input-700"
        >
          <template #close="{ close }">
            <BaseIcon
              name="close-bold"
              class="min-w-4 hover:text-blue-500 textmode-neutral w-4 h-4"
              @click="close"
            />
          </template>
        </BaseNotification>
      </template>
    </BaseNotifications>
  </div>
</template>

<style>
BaseNotification {
  z-index: 2000;
}
</style>